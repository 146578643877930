import { Card, Text, Link } from "@nextui-org/react";

export default function ExploreFranceCard() {
  return (
    <Card isPressable isHoverable css={{ p: "$6", mw: "500px" }}>
      <Card.Header>
            <Text h4 css={{ lineHeight: "$xs" }}>📕 L'Aventure Explore France</Text>
    </Card.Header>
    <Card.Body css={{ py: "$4",backdropFilter: "blur(8px)" }}>
        <Text>
        La France offre une merveilleuse succession de paysages, sauvages et verdoyants, ponctués de monuments incontournables. Notre objectif est de vous révéler les splendeurs de la France à travers des articles sur la France et ses nombreuses attractions.</Text>
      </Card.Body>
      <Card.Footer>
      <Link isExternal icon color="primary" target="_blank" href="#"> Découvrir le blog </Link>
      </Card.Footer>
    </Card>
  );
}
