import { Card, Text, Link } from "@nextui-org/react";

export default function MerchandisingCard() {
  return (
    <Card isPressable isHoverable css={{ p: "$6", mw: "500px" }}>
      <Card.Header>
            <Text h4 css={{ lineHeight: "$xs" }}>  🛍️ Les Compagnons</Text>
    </Card.Header>
    <Card.Body css={{ py: "$4",backdropFilter: "blur(8px)" }}>
        <Text>
        Lancé en 2019, les Compagnons sont la collection de merchandising exclusive de l'Odyssée Française. 
        Nous avons voulu mettre en place un merchandising qui célèbre l'esprit d'aventure.
        </Text>
      </Card.Body>
      <Card.Footer>
      <Link isExternal icon color="primary" target="_blank" href="#"> Projet en pause </Link>
      </Card.Footer>
    </Card>
  );
}
