import React, { useState } from 'react';
import { Button, Grid, Input, Text, Textarea, Spacer } from '@nextui-org/react';


const Contact = () => {
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Faites quelque chose avec les données du formulaire, par exemple envoyer une requête à un serveur

    // Réinitialiser les champs du formulaire
    setName('');
    setSubject('');
    setMessage('');
  };

  return (
    <div>
      <Text h2 weight="bold">Vous avez un projet, une suggestion ?</Text>
      <Spacer y={1} />
      <Text h3 >hello@odysseefrancaise.fr</Text>
      <form onSubmit={handleSubmit}>
      <Spacer y={1} />
        <Grid>
          <Input width="300px" bordered labelPlaceholder="Votre Nom" type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
        </Grid>
        <Spacer y={1} />
        <Grid>
          <Input  width="300px" bordered labelPlaceholder="Sujet" type="text" id="subject" value={subject} onChange={(e) => setSubject(e.target.value)} required />
        </Grid>
        <Spacer y={1} />
        <Grid>
        <Textarea  width="300px" placeholder="Votre message..."
          minRows={3} id="message" value={message} onChange={(e) => setMessage(e.target.value)} required ></Textarea>
        </Grid>
        <Spacer y={1} />
        <Button disabled bordered type="submit">Envoyer</Button>
      </form>
    </div>
  );
};

export default Contact;
