import { Card, Text, Link } from "@nextui-org/react";

export default function TripPlannerCard() {
  return (
    <Card isPressable isHoverable css={{ p: "$6", mw: "500px" }}>
      <Card.Header>
            <Text h4 css={{ lineHeight: "$xs" }}>🗺️ Planificateur d'itinéraire</Text>
    </Card.Header>
    <Card.Body css={{ py: "$4" }}>
        <Text>
        À travers cette application nous voulons concevoir un outil en ligne innovant pour accompagner les voyageurs, les photographes et les passionnés d'activités en plein air dans la planification et l'organisation de leurs aventures à travers la France.
        </Text>
      </Card.Body>
      <Card.Footer>
      <Link isExternal icon color="primary" target="_blank" href="#"> Version Beta du Planificateur </Link>
      </Card.Footer>
    </Card>
  );
}
