import { Card, Text, Link } from "@nextui-org/react";

export default function AuranovaCard() {
  return (
    <Card isPressable isHoverable css={{ p: "$6", mw: "500px" }}>
      <Card.Header>
            <Text h4 css={{ lineHeight: "$xs" }}>📸 Odyssée Studio</Text>
    </Card.Header>
    <Card.Body css={{ py: "$4",backdropFilter: "blur(8px)" }}>
        <Text>
        Odyssée Studio est une agence de communication innovante, spécialisée dans l'accompagnement des marques et des entreprises souhaitant créer une identité de marque forte et percutante. Notre équipe d'experts vous aide à vous implanter et à développer votre image de marque sur les réseaux sociaux grâce à des services sur mesure, tels que la création de contenu et le community management, le tout sous forme d'abonnement flexible.
        </Text>
        </Card.Body>
      <Card.Footer>
      <Link isExternal icon color="primary" target="_blank" href="mailto:hello@odysseefrancaise.fr"> Nous contacter </Link>
      </Card.Footer>
    </Card>
  );
}
