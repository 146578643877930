import { useTheme as useNextTheme } from 'next-themes';
import { useTheme, Switch, Container, Grid, Text } from '@nextui-org/react';
import { MoonIcon, SunIcon } from '@heroicons/react/24/solid'
import './global.css';
import BioPage from './pages/BioPage';

function App() {
  const { setTheme } = useNextTheme();
  const { isDark } = useTheme();

  const toggleTheme = () => {
    setTheme(isDark ? 'light' : 'dark');
  };

  return (
    <div className={`App ${isDark ? 'dark' : ''}`} style={{alignItems: "center", justifyContent: "center", minHeight: "100vh", paddingTop: "20px", paddingBottom: "40px" }}>
      <Container css={{ p: "$6", mw: "500px" }}>
            <Grid.Container justify="center" align="center" gap={1}>
            
            <Grid justify="center" align="center" xs={12}>
            <Text h1 weight="normal" style={{ fontFamily: "Eugello"}}>
                L' Odyssée Française</Text>
            </Grid>
            </Grid.Container>
      <Switch color="primary" bordered checked={isDark} onChange={toggleTheme} icon={isDark ? <MoonIcon /> : <SunIcon className="theme-icon" />} /> 
      <BioPage />
      </Container>
    </div>
  );
}

export default App;
