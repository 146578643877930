import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, NextUIProvider } from "@nextui-org/react";
import { ThemeProvider as NextThemesProvider } from 'next-themes';


const lightTheme = createTheme({
  type: "light",
  theme: {
    colors: {
      primaryLight: '#gray900',
      primaryLightHover: '#green300',
      primaryLightActive: '#green400',
      primaryLightContrast: '#green600',
      primary: '#23262F',
      primaryBorder: '#23262F',
      primaryBorderHover: '#green600',
      primarySolidHover: '#green700',
      primarySolidContrast: '#white',
      primaryShadow: '#23262F',

      gradient: 'linear-gradient(112deg, #blue100 -25%, #pink500 -10%, #purple500 80%)',
      link: '#5E1DAD',

      white:'#fff',

      // ... more colors
    },
    space: {},
    fonts: {
      h1: "'Eugello', sans-serif",
    },
  },
});

const darkTheme = createTheme({
  type: "dark",
  theme: {
    colors: {
      background: '#1d1d1d',
      text: '#fff',
      primary:'#fff',

    },
    space: {},
    fonts: {
      h1: "'Eugello', sans-serif",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <NextThemesProvider
    defaultTheme="system"
    attribute="class"
    value={{
      light: lightTheme.className,
      dark: darkTheme.className
    }}
  >
  <NextUIProvider>
    <App />
  </NextUIProvider>
</NextThemesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
