import React from 'react';
import { Container, Grid, Spacer, Text, Link } from "@nextui-org/react";
import TripPlannerCard from '../components/TripPlanneCard';
import Contact from '../components/Contact';
import ExploreFranceCard from '../components/ExploreFrance';
import MerchandisingCard from '../components/Merchandising';
import AuranovaCard from '../components/Auranova';


const BioPage = () => {
  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: "100vh" }} className="home-container">
        <Container>
            <Grid.Container justify="center" align="center" gap={1}>

            <Grid>
                <Grid.Container justify="center" align="center" xs={12} css={{ py: "$2" }}>
                    <Text>L'Odyssée Française est un projet qui célèbre la beauté, la diversité et la richesse culturelle de notre cher pays.</Text>
                    <Spacer y={1} />
                    <Link isExternal icon color="primary" target="_blank" href="https://docs.odysseefrancaise.fr/"> Lire notre documentation </Link>
                    <Spacer y={1} />
                    <Text><b>🚀  Notre objectif ? </b>Rassembler une communauté de photographes passionnés, de voyageurs aventureux et d'explorateurs intrépides pour mettre en lumière les trésors cachés et les créateurs uniques qui font la magie de la France. </Text>
                    <Spacer y={1} />
                    <Link isExternal icon color="primary" target="_blank" href="#"> Notre Discord </Link>
                    <Spacer y={1} />
                    <Link isExternal icon color="primary" target="_blank" href="https://fr.tipeee.com/explorefrance/"> Notre Patreon </Link>
                    <Spacer y={1} />
                </Grid.Container>
            </Grid>

            <Spacer y={3} />

            <Grid justify="center" align="center" xs={12}>
                <Text h2 weight="bold">Nos Projets</Text>
            </Grid>

            <Grid justify="center" align="center" xs={12}>
                <TripPlannerCard />
            </Grid>

            <Spacer y={1} />

            <Grid justify="center" align="center" xs={12}>
                <ExploreFranceCard />
            </Grid>
            <Spacer y={1} />
            
            <Grid justify="center" align="center" xs={12}>
                <MerchandisingCard />
            </Grid>
            <Spacer y={1} />
            
            <Grid justify="center" align="center" xs={12}>
                <AuranovaCard />
            </Grid>

            <Spacer y={3} />

            <Grid justify="center" align="center" xs={12}>
                <Contact />
            </Grid>

            </Grid.Container>
      
      </Container>
    </div>
  );
};

export default BioPage;
